.align-center{

    display: flex;
    justify-content: center;
    
}

a.NeoTooltip{

    cursor: pointer;
    font-weight: 600;
    height: 20px;
    border: 2px solid #9399a6 !important;
    padding: 5px;
    border-radius: 50%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9399a6;

}

a.NeoTooltip:hover{
    color: #9399a6 !important;
}