*{
    box-shadow: none !important;
}

.s3-btn{
    user-select: none;
}

.space{
    padding: 0 2%;
}

.externalSpace{
    padding: 0 2% !important;
}

.inline {
    display: inline;
}

.pointer{
    cursor: pointer
}

.s3-btn:hover{
    color: white
}

.neoLink{
    margin-top: 15px;
}

.neoLink a{
    color: #2879db !important;
    text-decoration: none !important;
}
.neoLink:hover{
    cursor: pointer;
    text-decoration: underline !important;
}

.whiteColor{
    transition-duration: 0.2s;
    color: #fff !important;
}


.btn-group.btn-group-toggle .btn.btn-primary{
    display: flex;
    justify-content: center;
    border: none;
    background: none;
    color: gray;
    box-shadow: none !important;
}

.btn-group.btn-group-toggle .btn.btn-primary.active{
    display: flex;
    justify-content: center;
    border: none ;
    background: none;
    color: #2879db;
    box-shadow: none !important;
}

.btn-group.btn-group-toggle .btn.btn-primary:before{
    content: url('../../images/desmarcado.png');
    margin-right: 7px;
}

.btn-group.btn-group-toggle .btn.btn-primary.active:before{
    content: url('../../images/marcado.png');
    margin-right: 7px;
}

.btn-group.btn-group-toggle{
    z-index: 0 !important;
}

.btn-toolbar .btn-group.btn-group-toggle label{
    padding-left: 0 !important;
}

.removePadding{
    padding: 0 !important;
}


.loginBtn button{
    margin-top: 50px !important;
    padding: 10px 44px !important;
}

.percentText{
    background-color: #fff;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-size: 14px;
    display: inline-block;
    padding: 17px 15px;
    margin-top: -1px;
    margin-right: 5px;
    border-radius: 30px;
    transform: translateY(-50%);
    transition: all 300ms;
}

.floatRight{
    float: right;
}