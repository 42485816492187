
@import url("../../../assets/css/bootstrap.min.css");
@import url("../../../assets/css/icons.css");
@import url("../../../assets/css/social.css");
@import url("../../../assets/css/slick.css");

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(../../../assets//fonts/Poppins-Regular.ttf) format('truetype');
  }


*{
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box!important;
    list-style-type: none;
    line-height: 100%;
    outline: 0!important;
}

body {
    font-family: 'Poppins';
    font-size: 14px;
    color: #9399a6;
  }

  /*APAGAR AQUI PARA CHECKBOX FUNCIONAR*/
.s3-signup input{
    /*border: 1px solid #9298a6;*/
    display: block;
    width: 100%;
    /*padding: 10px 15px 8px;*/
    font-size: 15px;
    height: 46px;
    border-radius: 0;
    line-height: 27px;
    color: #2879db;
    box-shadow: none!important;
    outline: 0!important;
    transition: all .3s;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.s3-signup label{
    color: #1388b9;
}

.s3-signup label:not(.s3-cb) span {
    font-size: 14px;
    line-height: 130%;
    margin-top: 1px;
}

.s3-signup .verticalAlign {
    display: flex;
    align-items: center;
}


/*STEP INICIAL E FINAL*/
.stepInicial .listaDocumentos,
.stepFinal .listaDocumentos {
  padding: 20px;
}
.stepInicial .listaDocumentos li,
.stepFinal .listaDocumentos li {
  margin-bottom: 12px;
}

.stepInicial .containerDocumentos,
.stepFinal .containerDocumentos {
  padding: 0 40px;
}

.stepInicial .containerDocumentos h5,
.stepFinal .containerDocumentos h5 {
  font-weight: 900;
}

/* .stepFinal .containerGeral {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 40vh;
} */

.stepInicial .listaDocumentos ul li,
.stepFinal .listaDocumentos ul li {
  list-style-type: circle;
}

/************/

label {
    font-weight: 400;
}

.register-title, .register-header{
    margin: 0 auto;
}

.strong900{
    font-weight: 900;
}


.btnDependentes{
    
    padding: 2px 20px;
    margin: 0 10px;
    border-radius: 360px;
    background-color: #fff;
    border: none;
    color: blue;
    -webkit-box-shadow: 5px 3px 8px 1px rgba(145,143,145,0.2);
    -moz-box-shadow: 5px 3px 8px 1px rgba(145,143,145,0.2);
    box-shadow: 5px 3px 8px 1px rgba(145,143,145,0.2) !important; 
}


#numberEmployees{
    width: 70%;
}

#cardData div{
    margin-bottom: 10px;
}

/* Customização do cartão */

    /* MASTERCARD */
.rccs__card.rccs__card--mastercard .rccs__card__background{
    background: linear-gradient(25deg, #dedede, #505050) !important;
}

.rccs__card.rccs__card--mastercard .rccs__number{
    color: white !important;
}
.rccs__card.rccs__card--mastercard .rccs__name{
    color: white !important;
}

.rccs__card.rccs__card--mastercard .rccs__expiry__value{
    color: white !important;
}

.rccs__card.rccs__card--mastercard .rccs__expiry__valid{
    color: white !important;
}


/* VISA */

.rccs__card .rccs__number{
    color: white !important;
}
.rccs__card .rccs__name{
    color: white !important;
}

.rccs__card .rccs__expiry__value{
    color: white !important;
}

.rccs__card .rccs__expiry__valid{
    color: white !important;
}
.rccs__card .rccs__cvc__front{
    color: white !important;
}


/* discover */

.rccs__card.rccs__card--discover .rccs__number{
    color: black !important;
}
.rccs__card.rccs__card--discover .rccs__name{
    color: black !important;
}

.rccs__card.rccs__card--discover .rccs__expiry__value{
    color: black !important;
}

.rccs__card.rccs__card--discover .rccs__expiry__valid{
    color: black !important;
}


/* dinersclub */

.rccs__card.rccs__card--dinersclub .rccs__number{
    color: black !important;
}
.rccs__card.rccs__card--dinersclub .rccs__name{
    color: black !important;
}

.rccs__card.rccs__card--dinersclub .rccs__expiry__value{
    color: black !important;
}

.rccs__card.rccs__card--dinersclub .rccs__expiry__valid{
    color: black !important;
}




.valorTotal span{
    font-size: 20px;
}
.valorTotal{
    margin-top: 5px;
    width: max-content;
}

.valorDesconto {
    color: #b30f0f;
    text-decoration: line-through;
}

.bigSpace {
    min-height: 200px;
}

.rowsContainer{
    max-height: 200px;
    min-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
}

/* Customização da scrollbar */
/* width */
::-webkit-scrollbar {
    width: 9px !important;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 20px;
    background: #f1f1f1 !important; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c8c8c8 !important; 
    border-radius: 20px !important;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1 !important;  
  }

  .removePadding{
      padding: 0 !important;
  }

  .register-header h4{
    width: 80%;
    font-size:18px;
    text-align: center;
    margin: 0 auto;
  }

  /* Ajustes Step B2 */

@media (min-width: 768px) and (max-width: 991px){
    .rccs{
        margin-left: -21px !important;
    }
}

@media (min-width: 768px){
    #colCVV, #colValidade{
        width: 50%;
    }
    #colCVV{
        padding-right: 0;
    }
    #colValidade{
        padding-left: 0;
    }
}

@media (max-width: 768px){
    #colCVV, #colValidade{
        padding: 0;
    }
    .rccs{
        margin-bottom: 10px !important;
    }
    #colCVV, #colValidade{
        min-width: 100%;
    }
}

@media (max-width: 440px){
    .rccs{
        margin-left: -17px !important;
    }
}

@media (max-width: 470px){
    .rccs__card--front, .rccs__card--back {
        height: 178px;
        max-width: 265px;
    }
}

@media (max-width: 425px){
    .rccs{
       display: none;
    }
}


/*Ajustes Step C */

.btnChoice{
    margin: 5px 0;
}

/* Ajustes Step F2 */
.s3-signup{
    margin: 15px;
}

/* Ajustes no Título */
@media (max-width: 430px){
    .register-header, .register-header h3{
        font-size: 20px;
    }

    .register-header h4{
        font-size: 16px;
    }
}

/*Ajustes nos Contratos*/
@media (max-width: 860px){

}

/* Ajustes Step Z */
.s3-cadastro[data-step="Z"]{
    height: 100vh
  }  

  #s3-cadastro.totalHeight[data-step="Z"]{
  height: 100% !important;
}  

