.modal, .loading {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
    margin: 0 auto;
    background-color: #0000006b;
    display: flex;
    align-items: center;
}

@media (min-width: 768px){
    .loading:not(.responsive){
        height: 84%;
    }
}

@media (max-width: 425px){
    .loading:not(.responsive){
        height: 93%;
    }
}

.modal-content, .loading-content{
    border-radius: 0;
    background-color: #ffffff;
    padding: 65px 0 47px;
    text-align: center;
    width: fit-content;
}

.diminuir-modal .modal-content{
    width: 70%;
}

.camera-content{
    border-radius: 0;
    background-color: #ffffff;
    text-align: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 0;
}

.modal-title{
    font-weight: 700;
    margin: 0 0 31px;
    color: #a5a5a5;
}

.loading-title{
    font-weight: 700;
    margin: 0 70px 31px;
    color: #a5a5a5;
}

.motal-text{
    width: 55%;
    line-height: 1.3;
    font-size: 18px;
}

.div-btn{
   margin: 50px 15px 0 15px;
}

.modal-btn {
    background-color: #2879db;
    color: white !important;
    padding: 12px 24px;
    border-radius: 360px;
    cursor: pointer;
    margin: 32px 0 0 0;
    transition-duration: 0.2s;
}
.modal-btn:hover {
    background-color: gray;
    color: white;
    transition-duration: 0.2s;
}
.align-center{
    display: flex;
    justify-content: center;
}

.align-end{
    display: flex;
    justify-content: flex-end;
}

.positionFixed{
    position: fixed !important
}

.space{
    padding: 0 2%;
}

.cameraImage{
    max-width: 100%;
    margin-top: 20px;
}

.cameraButton{
    border: 1px solid #a1a1a1;
    padding: 10px;
}

#salvarImagem{
    width: 50%;
}
#outraFoto{
    width: 50%;
}
#cancelarFoto{
    width: 100%;
}


.blockScroll{
    overflow: hidden
}

#cameraDiv{
    margin-top: 20px;
    background-color: #969696;
}

.erroButton{
    margin: 30px;
}

.modalFullScreen{
    height: 100vh !important;
}

.s3-login.page-login .modalFullScreen {
    position: fixed;
    height: 100% !important;
    top: 0;
    left: 0;
}