.userName, .userProgress{
    color: white;
    margin-right: 27px;
    font-weight: 100;
}

.login{
    cursor: pointer;
    font-weight: 900;
}


.removePadding{
    padding: 0 !important;
}

.s3-navigation.navbar{
    width: 100%;
    margin-bottom: 0;
}

.navItens .navbar-text a, .navItens .navbar-text .userName{
    color: #fff;
}

.navbar-dark .navbar-toggler{
    color: #fff;
    border-color: #fff;
}

@media (max-width: 699px){
    .brandImage{
        display: none;
    }
    .smallBrandImage{
        width: 85%;
        display: flex;
    }
    .justify-content-end{
        width: 80%;
        display: flex;
        justify-content: flex-end;
    }

    .navbar-text{
        margin-bottom: 0px;
        margin-top: 0px !important;
        /* display: block; */
    }
}
@media (min-width: 700px){
    .smallBrandImage{
        display: none;
    }
}

@media (min-width: 768px){
    header .s3-flex-wrapper .s3-navigation .justify-content-end{
        display: flex !important;
        width: 100%;
    }
    .navbar-text{
        display: inline;
    }
}
