@font-face {
  font-family: "Flaticon";
  src: url("../iconpack/icons.eot");
  src: url("../iconpack/icons.eot?#iefix") format("embedded-opentype"),
       url("../iconpack/icons.woff") format("woff"),
       url("../iconpack/icons.ttf") format("truetype"),
       url("../iconpack/icons.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../iconpack/icons.svg#Flaticon") format("svg");
  }
}
[class^="s3-icon-"]:before, [class*=" s3-icon-"]:before,
[class^="s3-icon-"]:after, [class*=" s3-icon-"]:after {   
  font-family: Flaticon;
  font-style: normal;
}
.s3-icon-arrow-left-01:before { content: "\f100"; }
.s3-icon-arrow-left-02:before { content: "\f101"; }
.s3-icon-arrow-right-01:before { content: "\f102"; }
.s3-icon-arrow-right-02:before { content: "\f103"; }
.s3-icon-arrow-top-01:before { content: "\f104"; }
.s3-icon-check:before { content: "\f105"; }
.s3-icon-close:before { content: "\f106"; }
.s3-icon-help:before { content: "\f107"; }
.s3-icon-menu:before { content: "\f108"; }
.s3-icon-menu-close:before { content: "\f109"; }