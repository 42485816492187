@font-face {
  font-family: "Social";
  src: url("../socialicons/social.eot");
  src: url("../socialicons/social.eot?#iefix") format("embedded-opentype"),
       url("../socialicons/social.woff") format("woff"),
       url("../socialicons/social.ttf") format("truetype"),
       url("../socialicons/social.svg#Social") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Social";
    src: url("../socialicons/social.svg#Social") format("svg");
  }
}
[class^="s3-social-"]:before, [class*=" s3-social-"]:before,
[class^="s3-social-"]:after, [class*=" s3-social-"]:after {   
  font-family: Social;
  font-style: normal;
}
.s3-social-gplus:before { content: "\f100"; }
.s3-social-linkedin:before { content: "\f101"; }
.s3-social-twitter:before { content: "\f102"; }
.s3-social-instagram:before { content: "\f103"; }
.s3-social-facebook:before { content: "\f104"; }