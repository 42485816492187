select.NeoSelect option{
    color: #2879db;
}
select.NeoSelect option:disabled{
    color: #9999b2;
}


.corAzul{
    color: #2879db !important;
}

.corCinza{
    color: #9999b2 !important;
}


/* Customização do Input Required Special */
.NeoDesign{
    border: 1px solid darkgrey;
    display: flex;
    align-items: center;
    padding: 0 4%;
}

.NeoDesign span{
    font-size: 15px;
}

.NeoDesign input{
    background: none !important;
    border: none !important;
    padding-top: 9px !important;
}

/* Customização do Select */
.css-yk16xz-control{
    border-radius: 0 !important;
    background-color: transparent !important;
}

.css-1hb7zxy-IndicatorsContainer{
    align-self: auto !important;
    display: block !important;
}

.css-yk16xz-control{
    border-color: #9298a6 !important;
}

.css-1pahdxg-control{
    border-color: #b3bdcc !important;
    border-radius: 0 !important;
    background-color: transparent !important;
}

.css-1uccc91-singleValue{
    color: #2879db !important;
}

.css-1hwfws3, .css-yk16xz-control{
    height: 46px;
    display: flex;
    align-content: center;
}

.css-b8ldur-Input input{
    animation: none !important;
    transition: none !important;
}

.loginInput{
    border: none;
    border-bottom: 1px solid #adadad;
    border-radius: 0;
    color: #2383de !important;
    margin: 21px 0;
    background-color: transparent;
}

input#login {
    background-color: transparent !important;
}

input#password {
    background-color: transparent !important;
}

@media (max-width: 768px){
    input{
        margin: 10px 0;
    }
}