/*
Website: neocontador.com.br
Demo URI: http://neocontador.com.br/
Version: 1.0.0
*/
/*-------------------------------------------------------------
>>> TABLE OF CONTENTS
---------------------------------------------------------------

# Imports
# Body
# Elements
# Main Header
-------------------------------------------------------------*/
/* Import other CSS files */
@import url("./bootstrap.min.css");
@import url("./icons.css");
@import url("./social.css");
@import url("./slick.css");

/* Import other LESS files */
/* Reset */
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box !important;
  list-style-type: none;
  line-height: 100%;
  outline: none !important;
}

*:active {
  outline: none !important;
}

form input {
  line-height: initial;
}

form select,
form input,
form textarea {
  box-shadow: none !important;
  outline: none !important;
}

form select:active,
form input:active,
form textarea:active,
form select:focus,
form input:focus,
form textarea:focus {
  box-shadow: none !important;
  outline: none !important;
}

a,
a:link,
a:hover,
a:active,
a:visited {
  text-decoration: none !important;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0;
}

ul,
ol {
  margin: 0;
  list-style-type: none;
}

label {
  font-weight: normal;
}

.register-body label.form-control-label {
  line-height: 140%;
}

.ff-1 {
  font-family: 'Poppins';
}

.ff-2 {
  font-family: 'Open Sans';
}

.cssload-container {
  height: 49px;
  text-align: center;
}

.cssload-container .cssload-speeding-wheel {
  width: 49px;
  height: 49px;
  margin: 0 auto;
  border: 3px solid #1388b9;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 575ms infinite linear;
  -o-animation: cssload-spin 575ms infinite linear;
  -ms-animation: cssload-spin 575ms infinite linear;
  -webkit-animation: cssload-spin 575ms infinite linear;
  -moz-animation: cssload-spin 575ms infinite linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Body */
* {
  font-family: 'Poppins';
  font-size: 15px;
  color: #9399a6;
}

.page-cadastro,
.page-login {
  background-image: url('../data/images/bg-cadastro.png');
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Animations */
@-webkit-keyframes ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
  }
}

@-moz-keyframes ripple {
  100% {
    opacity: 0;
    -moz-transform: scale(2.5);
  }
}

@-o-keyframes ripple {
  100% {
    opacity: 0;
    -o-transform: scale(2.5);
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

/* Element :: To Top */
#toTop {
  position: fixed;
  right: 36px;
  bottom: 101px;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all 400ms;
}

#toTop.active {
  opacity: 1;
  visibility: visible;
}

#toTop:before,
#toTop:after {
  transition: all 400ms;
  position: absolute;
  right: calc(100% - 5px);
  top: 50%;
  transform: translateY(-50%);
  margin-top: -2px;
  opacity: 0;
  visibility: hidden;
}

#toTop:before {
  content: 'Voltar ao topo';
  white-space: nowrap;
  margin-right: 15px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: white;
  display: inline-block;
  padding: 6px 7px 4px;
  border-radius: 2px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}

#toTop:after {
  content: '';
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid white;
  margin-right: 9px;
}

#toTop:hover:before,
#toTop:hover:after {
  visibility: visible;
  opacity: 1;
  right: 100%;
}

#toTop a {
  display: inline-block;
  width: 52px;
  height: 52px;
  position: relative;
  background-color: white;
  border-radius: 5px;
  font-size: 22px;
  color: #1388b9;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  transition: all 300ms ease;
}

#toTop a:hover {
  color: #00bf1b;
}

#toTop a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1px;
}

@media (max-width: 992px) {
  #toTop {
    display: none;
  }
}

/* Element :: Buttons */
.s3-btn {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
  padding: 21px 30px 17px;
  border-radius: 5px;
  letter-spacing: 2px;
  border: none;
  transition: all 300ms;

}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: #fff;
}

.principal .s3-btn:hover {
  color: #1388b9;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.s3-btn:active {
  box-shadow: none;
}

.s3-btn.green {
  background-color: #00bf1b;
  color: white;
  transition: all 300ms ease;
}

.s3-btn.green:hover {
  background-color: #00c91c;
}

.s3-btn.grey {
  background-color: #9399a6;
  color: white;
}

.s3-btn.white {
  background-color: white;
  color: #1388b9;
}

.s3-btn.material {
  position: relative;
  overflow: hidden;
}

.s3-btn.material .ink {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.s3-btn.material .ink.animate {
  -webkit-animation: ripple 0.65s linear;
  -moz-animation: ripple 0.65s linear;
  -ms-animation: ripple 0.65s linear;
  -o-animation: ripple 0.65s linear;
  animation: ripple 0.65s linear;
}

.s3-btn.material.green .ink {
  background-color: #00b51a;
}

.s3-btn.material.white .ink {
  background-color: #d7f0f8;
}

.s3-btn.arrow-right {
  position: relative;
  overflow: hidden;
}

.s3-btn.arrow-right i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: normal;
  right: -40px;
  opacity: 0;
  visibility: hidden;
  margin-top: 1px;
  transition: all 300ms;
}

.s3-btn.arrow-right:hover {
  padding-right: 55px;
  padding-left: 30px;
}

.s3-btn.arrow-right:hover i {
  opacity: 1;
  visibility: visible;
  right: 20px;
}

.s3-btn.s3-btn-round {
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 14px;
  display: inline-block;
  padding: 17px 35px 15px;
  margin-top: -1px;
  border-radius: 30px;
  transform: translateY(-50%);
  transition: all 300ms;
}

@media (max-width: 425px) {
  .s3-btn.s3-btn-round {
    padding: 11px 25px 12px !important;
  }
}

@media (max-width: 350px) {
  .s3-btn.s3-btn-round {
    font-size: 12px !important;
    padding: 9px 19px 12px !important;
  }
}

.s3-icon-menu {
  color: #20b0fc;
  font-size: 30px;
}

.s3-btn.s3-btn-round.white {
  background-color: white;
  color: #9399a6;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.s3-btn.s3-btn-round.white:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  color: #858c9a;
}

.s3-btn.s3-btn-round.green {
  background-color: #00bf1b;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 191, 27, 0.4);
}

.s3-btn.s3-btn-round.green:hover {
  background-color: #00c91c;
  box-shadow: 0 2px 3px rgba(0, 191, 27, 0.4);
}

.s3-btn.s3-btn-round.green[data-next=""] {
  background-color: #b1b1b1;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  color: #ffffff !important;
}

.s3-btn.s3-btn-round.finish {
  background-color: #00bf1b;
  color: white;
  box-shadow: 0 2px 10px rgba(0, 191, 27, 0.4);
}

.s3-btn.s3-btn-round.finish:hover {
  background-color: #00c91c;
  box-shadow: 0 2px 3px rgba(0, 191, 27, 0.4);
}

/* Element :: Form - Basic */
form.s3-basic .s3-form-item {
  margin-bottom: 20px;
}

form.s3-basic input,
form.s3-basic textarea {
  border: 1px solid #9298A6;
  display: block;
  width: 100%;
  padding: 10px 15px 8px;
  font-size: 15px;
  line-height: 30px;
  color: #122143;
  box-shadow: none !important;
  outline: none !important;
  transition: all 300ms;
  background-color: transparent;
}

form.s3-basic input:focus,
form.s3-basic textarea:focus {
  border-color: #1388b9;
}

form.s3-basic button[type="submit"] {
  padding-left: 70px;
  padding-right: 70px;
}

form.s3-basic button[type="submit"]:hover {
  padding-left: 55px;
  padding-right: 85px;
}

/* Element :: Form - Signup */
form.s3-signup .row[data-active="false"] input:not([type="checkbox"]),
form.s3-signup .row[data-active="false"] input:not([type="radio"]),
form.s3-signup .row[data-active="false"] input:not([type="file"]),
form.s3-signup .row[data-active="false"] textarea {
  border-color: #d7d7d7;
  color: #d7d7d7;
}

form.s3-signup .row[data-active="false"] input:not([type="checkbox"])::-webkit-input-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="radio"])::-webkit-input-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="file"])::-webkit-input-placeholder,
form.s3-signup .row[data-active="false"] textarea::-webkit-input-placeholder {
  color: #d7d7d7 !important;
}

form.s3-signup .row[data-active="false"] input:not([type="checkbox"]):-moz-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="radio"]):-moz-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="file"]):-moz-placeholder,
form.s3-signup .row[data-active="false"] textarea:-moz-placeholder {
  color: #d7d7d7 !important;
}

form.s3-signup .row[data-active="false"] input:not([type="checkbox"])::-moz-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="radio"])::-moz-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="file"])::-moz-placeholder,
form.s3-signup .row[data-active="false"] textarea::-moz-placeholder {
  color: #d7d7d7 !important;
}

form.s3-signup .row[data-active="false"] input:not([type="checkbox"]):-ms-input-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="radio"]):-ms-input-placeholder,
form.s3-signup .row[data-active="false"] input:not([type="file"]):-ms-input-placeholder,
form.s3-signup .row[data-active="false"] textarea:-ms-input-placeholder {
  color: #d7d7d7 !important;
}

form.s3-signup .row[data-active="false"] input[type="file"]+label {
  background-color: #d7d7d7;
  color: white;
}

form.s3-signup .row[data-active="false"] input[type="file"]+label:hover {
  background-color: #d7d7d7;
  color: white;
}

form.s3-signup .row[data-active="false"] label:not(.s3-cb) {
  color: #d7d7d7;
}

.s3-checkbox input:not(.same_row) {
  cursor: default;
}

.s3-checkbox input:not(.same_row)+label {
  cursor: default;
  color: #d7d7d7;
}

.s3-checkbox input:not(.same_row)+label::before {
  border-color: #d7d7d7;
}

form.s3-signup .s3-form-item {
  margin-bottom: 20px;
}

form.s3-signup .s3-form-item .s3-select {
  position: relative;
}

form.s3-signup .s3-form-item .s3-select::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #9399a6;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

form.s3-signup .s3-form-item .s3-select.disabled::before {
  border-top-color: #d7d7d7;
}

form.s3-signup .s3-form-item .s3-select select:disabled {
  color: #d7d7d7;
  border-color: #d7d7d7;
}

form.s3-signup .s3-form-item .s3-file {
  display: inline-block;
  position: relative;
}

form.s3-signup .s3-form-item .s3-file i.s3-icon-close {
  width: 20px;
  height: 20px;
  background-color: #f16b6b;
  border-radius: 50%;
  font-size: 7px;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  transform: translate(50%, -50%);
  margin: 4px 1px 0 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}

form.s3-signup .s3-form-item .s3-file i.s3-icon-close::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

form.s3-signup .s3-form-item .s3-file input.has-file+label+i {
  visibility: visible;
  opacity: 1;
}

form.s3-signup .s3-form-item .s3-table li {
  display: flex;
}

form.s3-signup .s3-form-item .s3-table li:not(:last-child) {
  margin-bottom: 5px;
}

form.s3-signup .s3-form-item .s3-table li span {
  width: 50%;
  border: 1px solid #9399a6;
  border-right: none;
  padding: 2px 15px 0;
  font-size: 14px;
  color: #9399a6;
  line-height: 30px;
  display: block;
  background-color: #eee;
}

form.s3-signup .s3-form-item .s3-table li input {
  width: 50%;
  padding: 3px 15px 0;
  font-size: 14px;
  height: auto;
}

.s3-signup select {
  border: 1px solid #9298a6;
  display: block;
  width: 100%;
  padding: 10px 15px 8px;
  font-size: 15px;
  height: 46px;
  border-radius: 0;
  line-height: 27px;
  color: #2879db;
  box-shadow: none !important;
  outline: 0 !important;
  -webkit-transition: all .3s;
  transition: all .3s;
  background-color: transparent;
}

form.s3-signup input:not([type="checkbox"]),
form.s3-signup input:not([type="radio"]),
form.s3-signup textarea,
form.s3-signup select {
  border: 1px solid #9298A6;
  display: block;
  width: 100%;
  padding: 10px 15px 8px;
  font-size: 15px;
  height: 46px;
  border-radius: 0;
  line-height: 27px;
  color: #2879db;
  box-shadow: none !important;
  outline: none !important;
  transition: all 300ms;
  background-color: transparent;
  appearance: none;
}

form.s3-signup input:not([type="checkbox"])::-webkit-input-placeholder,
form.s3-signup input:not([type="radio"])::-webkit-input-placeholder,
form.s3-signup textarea::-webkit-input-placeholder,
form.s3-signup select::-webkit-input-placeholder {
  color: #9399a6;
}

form.s3-signup input:not([type="checkbox"]):-moz-placeholder,
form.s3-signup input:not([type="radio"]):-moz-placeholder,
form.s3-signup textarea:-moz-placeholder,
form.s3-signup select:-moz-placeholder {
  color: #9399a6;
}

form.s3-signup input:not([type="checkbox"])::-moz-placeholder,
form.s3-signup input:not([type="radio"])::-moz-placeholder,
form.s3-signup textarea::-moz-placeholder,
form.s3-signup select::-moz-placeholder {
  color: #9399a6;
}

form.s3-signup input:not([type="checkbox"]):-ms-input-placeholder,
form.s3-signup input:not([type="radio"]):-ms-input-placeholder,
form.s3-signup textarea:-ms-input-placeholder,
form.s3-signup select:-ms-input-placeholder {
  color: #9399a6;
}

form.s3-signup input:not([type="checkbox"]):focus,
form.s3-signup input:not([type="radio"]):focus,
form.s3-signup textarea:focus,
form.s3-signup select:focus {
  border-color: #2879db;
}

form.s3-signup input:not([type="checkbox"]).error,
form.s3-signup input:not([type="radio"]).error,
form.s3-signup textarea.error,
form.s3-signup select.error {
  border-color: #f16b6b;
}

form.s3-signup input:not([type="checkbox"]).valid,
form.s3-signup input:not([type="radio"]).valid,
form.s3-signup textarea.valid,
form.s3-signup select.valid {
  border-color: #2879db;
}

form.s3-signup input:not([type="checkbox"])[type="file"],
form.s3-signup input:not([type="radio"])[type="file"],
form.s3-signup textarea[type="file"],
form.s3-signup select[type="file"] {
  display: none;
}

form.s3-signup input:not([type="checkbox"])[type="file"]+label,
form.s3-signup input:not([type="radio"])[type="file"]+label,
form.s3-signup textarea[type="file"]+label,
form.s3-signup select[type="file"]+label {
  background-color: #9399a6;
  color: white;
  font-size: 14px;
  border-radius: 3px;
  padding: 13px 15px 10px;
  position: relative;
  top: 3px;
  height: auto;
  cursor: pointer;
}

form.s3-signup input:not([type="checkbox"])[type="file"]+label:hover,
form.s3-signup input:not([type="radio"])[type="file"]+label:hover,
form.s3-signup textarea[type="file"]+label:hover,
form.s3-signup select[type="file"]+label:hover {
  background-color: #3e87df;
}

form.s3-signup input:not([type="checkbox"])[type="file"].has-file+label,
form.s3-signup input:not([type="radio"])[type="file"].has-file+label,
form.s3-signup textarea[type="file"].has-file+label,
form.s3-signup select[type="file"].has-file+label {
  background-color: #2879db;
}

form.s3-signup .s3-select select {
  padding-right: 49px;
}

form.s3-signup button[type="submit"] {
  padding-left: 70px;
  padding-right: 70px;
}

form.s3-signup button[type="submit"]:hover {
  padding-left: 55px;
  padding-right: 85px;
}

form.s3-signup label {
  position: relative;
}

form.s3-signup label.s3-information {
  padding-left: 25px;
}

form.s3-signup label.s3-information i {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #afb3bd;
  color: white;
  font-size: 8px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 800ms cubic-bezier(0.89, -0.385, 0.245, 1.005);
  transition-timing-function: cubic-bezier(0.89, -0.385, 0.245, 1.005);
  z-index: 99999;
}

form.s3-signup label.s3-information i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

form.s3-signup label+.s3-information {
  position: absolute;
  left: 37px;
  z-index: 999;
  top: calc(100% + -5px);
  width: 386px;
  min-width: 300px;
  background-color: white;
  padding: 10px;
  font-size: 13px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: all 800ms;
}

form.s3-signup label+.s3-information p {
  line-height: 140%;
}

form.s3-signup label+.s3-information p:last-child {
  margin-bottom: 0;
}

form.s3-signup label:hover.s3-information i {
  color: transparent;
  top: calc(100% + 2px);
  width: 11px;
  height: 11px;
  left: 19px;
  background-color: #2879db;
}

form.s3-signup label:hover+.s3-information {
  visibility: visible;
  opacity: 1;
}

form.s3-signup label:not(.s3-cb) {
  color: #1388b9;
  transition: all 300ms;
  display: flex;
  align-items: center;
  height: 46px;
  position: relative;
}

form.s3-signup label:not(.s3-cb) span {
  font-size: 14px;
  line-height: 130%;
  margin-top: 1px;
}

form.s3-signup label:not(.s3-cb) span:nth-child(3) {
  font-size: 12px;
  margin-top: 4px;
}

form.s3-signup label:not(.s3-cb).s3-cbl {
  height: 39px;
}

form.s3-signup label:not(.s3-cb).error {
  display: none !important;
}

.s3-checkbox {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  height: 39px;
  padding: 12px 0 8px;
}

.s3-checkbox:last-child {
  margin-right: 0;
}

.s3-checkbox label {
  display: inline-block;
  position: relative;
  padding: 2px 0 0 28px;
  vertical-align: top;
  color: #a7abb6;
  cursor: pointer;
  transition: color 0.3s;
}

.s3-checkbox label::before {
  content: '';
  border: 1px solid #9298A6;
  transition: all 0.3s;
}

.s3-checkbox input,
.s3-checkbox label::before {
  width: 20px;
  height: 20px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.s3-checkbox input {
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  z-index: 100;
}

.s3-checkbox input:checked+label {
  color: #2879db;
}

.s3-checkbox input:checked+label::before {
  border-color: #9399a6;
}

.s3-checkbox input:checked+label+svg {
  opacity: 1;
  visibility: visible;
}

.s3-checkbox svg {
  position: absolute;
  width: 13px;
  height: 14px;
  top: 50%;
  margin-top: -6px;
  left: 4px;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}

.s3-checkbox svg path {
  stroke: #2879db;
  stroke-width: 14px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.s3-choice a {
  background-color: #b1b1b1;
  color: #ffffff !important;
  font-size: 14px;
  border-radius: 3px;
  padding: 13px 15px 10px;
  position: relative;
  top: 3px;
  cursor: pointer;
  display: inline-block;
  width: 80%;
  height: auto;
  transition: all 300ms;
}

.s3-choice a:hover {
  background-color: #3e87df;
}

form.s3-signup .s3-choice.full-width a {
  width: 100% !important;
}

form.s3-signup input.dont_match {
  border-color: #ff0000;
  color: #ff0000;
}

form.s3-signup input.dont_match:focus {
  color: #2879db;
}

/* Header */
#mainHeader {
  padding: 20px 0;
  background-color: white;
  top: -100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: top 800ms;
}

#mainHeader.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

#mainHeader .s3-flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#mainHeader #mainNavigation ul {
  display: inline-block;
}

#mainHeader #mainNavigation ul li {
  display: inline-block;
  margin-right: 30px;
}

#mainHeader #mainNavigation ul li a {
  color: #1388b9;
  font-weight: 500;
  transition: all 300ms ease;
}

#mainHeader #mainNavigation ul li a:hover {
  color: #122143;
}

#mainHeader #mainNavigation ul li.special a,
#mainHeader #mainNavigation ul li.active a {
  color: #122143;
}

@media (min-width: 769px) and (max-width: 1024px) {
  #mainHeader #mainNavigation ul li {
    margin-right: 20px;
  }
}

#mainHeader #mainNavigation .s3-cta {
  display: inline-block;
}

#mainHeader #mainNavigation .s3-cta a {
  display: inline-block;
  padding: 8px 10px 5px;
  border-radius: 3px;
  background-color: #00bf1b;
  color: white;
  font-size: 13px;
  text-transform: uppercase;
  box-shadow: 0 1px 4px rgba(0, 191, 27, 0.4);
  transition: all 300ms;
}

#mainHeader #mainNavigation .s3-cta a:hover {
  background-color: #00c91c;
  box-shadow: 0 1px 2px rgba(0, 191, 27, 0.4);
}

#mainHeader .s3-mobile-navigation-toggler a {
  font-size: 26px;
  color: #1388b9;
  transition: all 300ms;
  display: inline-block;
  height: 26px;
}

#mainHeader .s3-mobile-navigation-toggler a:hover {
  color: #122143;
}

#mainHeader #mobileNavigation {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
}

#mainHeader #mobileNavigation ul {
  width: 30%;
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100%;
  box-shadow: 3px 0 15px rgba(0, 0, 0, 0.2);
  transition: all 500ms ease;
}

#mainHeader #mobileNavigation ul li {
  display: block;
  border-bottom: 1px solid #ddd;
}

#mainHeader #mobileNavigation ul li a {
  display: block;
  padding: 23px 19px 20px;
  font-size: 16px;
}

#mainHeader #mobileNavigation.showMobileNavigation {
  visibility: visible !important;
  opacity: 1 !important;
}

#mainHeader #mobileNavigation ul.showMobileNavigation {
  left: 0 !important;
}

@media (max-width: 800px) {
  #mainHeader #mobileNavigation ul {
    width: 50%;
  }
}

@media (max-width: 430px) {
  #mainHeader #mobileNavigation ul {
    width: 80%;
  }
}

#mainHeader #mobileNavigation.active {
  opacity: 1;
  visibility: visible;
}

#mainHeader #mobileNavigation.active ul {
  left: 0;
}

/* Section :: Jumbotron */
#jumbotron {
  background-image: url('../data/images/background2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}

#jumbotron h1 {
  font-size: 38px;
  text-align: center;
  margin: 90px 0 75px;
}

#jumbotron h1 span {
  font-weight: 300;
  color: #d7f0f8;
}

#jumbotron h1 strong {
  color: white;
  font-weight: 500;
  display: block;
  margin-top: 20px;
}

@media (max-width: 500px) {
  #jumbotron h1 {
    font-size: 32px;
    line-height: 120%;
  }

  #jumbotron h1 strong {
    line-height: 120%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  #jumbotron h1 {
    font-size: 34px;
  }
}

#jumbotron #carouselExampleAutoplaying {
  position: relative;
  padding: 0 50px;
}

#jumbotron .slider {
  display: block;
  position: relative;
  width: 100%;
}

#jumbotron .slider .carousel-control-prev {
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  margin: auto;
}

#jumbotron .slider .carousel-control-next {
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  margin: auto;

}

/*#jumbotron #carouselExample .carouselExample-arrows {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
#jumbotron #carouselExample .carouselExample-arrows button {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  border: none;
  color: #d7f0f8;
  font-size: 40px;
  position: absolute;
  transition: all 300ms ease;
}
#jumbotron #carouselExample #carouselExample-arrows button:hover {
  color: white;
}
#jumbotron #carouselExample #carouselExample-arrows button.s3-slick-prev {
  left: -8px;
}
#jumbotron #carouselExample #carouselExample-arrows button.s3-slick-prev:hover {
  left: -13px;
}
#jumbotron #carouselExample #carouselExample-arrows button.s3-slick-next {
  right: -8px;
}
#jumbotron #carouselExample #carouselExample-arrows button.s3-slick-next:hover {
  right: -13px;
}
@media (max-width: 400px) {
  #jumbotron #carouselExample #carouselExample-arrows button {
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  #jumbotron #carouselExample #carouselExample-arrows {
    display: none;
  }
}*/
#jumbotron #carouselExampleAutoplaying .carouselExample-navigation ul {
  text-align: center;
  z-index: 5;
  position: relative;
}

#jumbotron #carouselExampleAutoplaying .carouselExample-navigation ul li {
  display: inline-block;
  margin: 0 15px;
}

#jumbotron #carouselExampleAutoplaying .carouselExample-navigation ul li a {
  color: #d7f0f8;
  display: block;
  padding-bottom: 15px;
  border-bottom: 4px solid transparent;
  transition: all 300ms ease;
}

#jumbotron #carouselExampleAutoplaying .carouselExample-navigation ul li a:hover {
  color: white;
}

#jumbotron #carouselExampleAutoplaying .carouselExample-navigation ul li.active a {
  color: white;
  border-color: white;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  position: relative;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item {
  width: 100%;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper {
  display: flex;
  min-height: 426px;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-image {
  background-size: cover;
  background-position: center;
  flex-grow: 1;
}

@media (max-width: 992px) {
  #jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-image {
    height: 300px;
  }
}

@media (max-width: 740px) {
  #jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-image {
    display: none;
  }
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content {
  background-color: white;
  background-image: linear-gradient(to top, #EEEEEE, white 70%);
  width: 30%;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-warning {
  width: 100%;
  padding: 12px 0 10px;
  font-size: 12px;
  text-align: center;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-warning.red {
  background-color: #f16b6b;
  color: white;
}

@media (max-width: 350px) {
  #jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-warning {
    font-size: 11px;
  }
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-header {
  font-size: 20px;
  text-align: center;
  padding: 30px 30px 25px;
  line-height: 140%;
  border-bottom: 1px solid #ddd;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-body {
  text-align: center;
  color: #1388b9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-body .s3-starting {
  font-size: 20px;
  font-weight: 300;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-body .s3-price {
  margin: 16px 0 18px;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-body .s3-price:first-child {
  margin-top: 0;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-body .s3-price .s3-number {
  font-size: 40px;
  font-weight: bold;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-body .s3-price span:last-child:not(:first-child) {
  font-size: 20px;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-body .s3-ast {
  font-size: 15px;
  margin-top: 20px;
}

#jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content .s3-cta {
  margin-top: 37px;
}

@media (max-width: 1200px) {
  #jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content {
    width: 40%;
  }
}

@media (max-width: 992px) {
  #jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper .s3-content {
    width: 100%;
  }
}

@media (max-width: 992px) {
  #jumbotron #carouselExampleAutoplaying .carousel-inner .carousel-item .s3-flex-wrapper {
    display: block;
  }
}

@media (max-width: 400px) {
  #jumbotron #carouselExampleAutoplaying {
    padding: 0 20px;
  }
}

@media (max-width: 350px) {
  #jumbotron #carouselExampleAutoplaying {
    padding: 0 10px;
  }
}

/* Section :: Optimize */
#secOptimize {
  padding: 100px 0;
  background-color: #FCFCFC;
  text-align: center;
}

#secOptimize .s3-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 500px) {
  #secOptimize .s3-image {
    display: none;
  }
}

#secOptimize .s3-header h2 {
  font-size: 40px;
  font-weight: 300;
  color: #1388b9;
  margin: 55px 0 40px;
}

@media (max-width: 500px) {
  #secOptimize .s3-header h2 {
    margin-top: 0;
  }
}

#secOptimize .s3-body p {
  line-height: 140%;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 300;
}

#secOptimize .s3-body p:last-child {
  margin-bottom: 0;
}

#secOptimize .s3-body p a {
  color: #9399a6;
  transition: all 300ms ease;
}

#secOptimize .s3-body p a:hover {
  color: #1388b9;
}

#secOptimize .s3-body .s3-cta {
  margin-bottom: 32px;
}

/* Section :: Numbers */
#secNumbers {
  background-image: url('../data/images/bg-numbers.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 85px;
  position: relative;
  color: white;
  text-align: center;
}

#secNumbers .s3-badge {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

#secNumbers h2 {
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 15px;
}

@media (max-width: 350px) {
  #secNumbers h2 {
    font-size: 30px;
  }
}

#secNumbers h3 {
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 50px;
}

@media (max-width: 740px) {
  #secNumbers h3 {
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  #secNumbers h3 {
    font-size: 22px;
    line-height: 140%;
  }
}

#secNumbers .s3-numbers .s3-item .s3-number {
  font-size: 50px;
  font-weight: bold;
}

#secNumbers .s3-numbers .s3-item .s3-name {
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  #secNumbers .s3-numbers .s3-item .s3-name {
    font-size: 28px;
  }
}

#secNumbers .s3-numbers .s3-item .s3-description {
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  margin-top: 15px;
}

@media (max-width: 768px) {
  #secNumbers .s3-numbers .s3-item {
    margin-bottom: 30px;
  }
}

@media (max-width: 1300px) {
  #secNumbers {
    background-image: none;
    background-color: #337BD8;
  }
}

/* Section :: Testimonials */
#secTestimonials {
  padding: 100px 0;
  background-color: #F4FBFF;
}

#secTestimonials h2 {
  text-align: center;
  color: #1388b9;
  font-size: 38px;
  font-weight: normal;
  margin-bottom: 50px;
}

#secTestimonials .s3-item .s3-box {
  background-color: white;
  padding: 30px;
  position: relative;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

#secTestimonials .s3-item .s3-box .s3-content {
  font-size: 16px;
  color: #122143;
  line-height: 140%;
}

#secTestimonials .s3-item .s3-box .s3-info {
  font-size: 15px;
  margin-top: 27px;
}

#secTestimonials .s3-item .s3-box .s3-info p {
  margin-bottom: 10px;
}

#secTestimonials .s3-item .s3-box .s3-info p:last-child {
  margin-bottom: 0;
}

#secTestimonials .s3-item .s3-box:after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 15px solid white;
}

#secTestimonials .s3-item .s3-avatar {
  margin-top: 30px;
  text-align: center;
}

#secTestimonials .s3-item .s3-avatar img {
  border-radius: 50%;
  border: 4px solid white;
  display: inline-block !important;
}

#secTestimonials .slick-dots {
  text-align: center;
  margin-top: 30px;
}

#secTestimonials .slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

#secTestimonials .slick-dots li button {
  color: transparent;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1388b9;
  border: none;
  padding: 0;
  transition: all 300ms;
}

#secTestimonials .slick-dots li.slick-active button {
  background-color: #003170;
}

/* Section :: Call To Action */
#secCta .s3-header {
  padding: 100px 0 275px;
  background-image: linear-gradient(180deg, #2fd2fd 0%, #21a0fc 100%);
}

#secCta .s3-header h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  color: white;
}

#secCta .s3-header h2>span {
  position: relative;
  top: -13px;
  margin-right: 15px;
}

@media (max-width: 1200px) {
  #secCta .s3-header h2>span {
    display: block;
    margin-bottom: 20px;
    line-height: 120%;
  }
}

@media (max-width: 740px) {
  #secCta .s3-header h2>span {
    margin-right: 0;
  }
}

@media (max-width: 740px) {
  #secCta .s3-header {
    padding-bottom: 100px;
  }
}

/*
#secCta .s3-body {
  background-image: url('../data/images/bg-cta-left.png'), url('../data/images/bg-cta-right.png');
  background-position: top left, top right;
  background-repeat: no-repeat, no-repeat;
}
*/

#secCta .s3-body .s3-image {
  margin-top: -195px;
  text-align: center;
}

#secCta .s3-body .s3-image img {
  max-width: 100%;
}

@media (max-width: 740px) {
  #secCta .s3-body .s3-image {
    display: none;
  }
}

@media (max-width: 1170px) {
  #secCta .s3-body {
    background-image: none;
  }
}

/* Section :: Contact */
#secContact {
  padding: 100px 0;
}

#secContact .s3-flex-wrapper {
  display: flex;
  justify-content: space-between;
}

#secContact h3 {
  font-size: 38px;
  font-weight: 300;
  color: #1388b9;
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  #secContact h3 {
    text-align: center;
  }
}

#secContact .s3-faq .panel {
  box-shadow: none;
  margin-bottom: 20px;
}

#secContact .s3-faq .panel:last-child {
  margin-bottom: 0;
}

#secContact .s3-faq .panel .panel-heading {
  padding: 0;
}

#secContact .s3-faq .panel .panel-heading a {
  color: #2879db;
}

#secContact .s3-faq .panel .panel-heading a:before {
  content: '–';
}

@media (max-width: 480px) {
  #secContact .s3-faq .panel .panel-heading a:before {
    display: none;
  }
}

@media (max-width: 480px) {
  #secContact .s3-faq .panel .panel-heading {
    text-align: center;
  }
}

#secContact .s3-faq .panel .panel-body {
  border: none;
  padding: 22px 16px 5px;
}

#secContact .s3-faq .panel .panel-body p {
  margin: 0;
  line-height: 140%;
}

@media (max-width: 480px) {
  #secContact .s3-faq .panel .panel-body {
    text-align: center;
    padding: 22px 0 5px;
  }
}

@media (max-width: 992px) {
  #secContact .s3-faq {
    margin-bottom: 85px;
  }
}

#secContact .s3-contact p {
  line-height: 140%;
}

@media (max-width: 480px) {
  #secContact .s3-contact p {
    text-align: center;
  }
}

#secContact .s3-contact form {
  margin-top: 33px;
}

/* Page :: Cadastro */
.page-cadastro .s3-loader {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 200ms;
}

.page-cadastro .s3-loader.visible {
  opacity: 1;
  visibility: visible;
}

.s3-cadastro {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
}

.s3-cadastro #mainWrapper {
  /* margin-top: 400px; header cadastro encontrado!*/
  position: relative;
  z-index: 10;
  top: 30px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: all 1000ms;
  height: 0;
}

.s3-cadastro #myMainWrapper {
  margin-top: 400px;
  position: relative;
  z-index: 10;
  top: 30px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition: all 1000ms;
  height: 0;
}

@media only screen and (max-height: 390px) {
  .s3-cadastro.contratos {
    height: 100% !important;
  }
}

@media (max-height: 500px) {
  .s3-cadastro.contratos[data-step="Contratos"] {
    height: 100% !important;
  }
}

@media (max-height: 504px) {
  .s3-cadastro:not(.contratos):not(.specialSize) {
    height: 100% !important;
  }
}

@media (max-width: 768px) {
  .s3-cadastro.contratos {
    height: 100% !important;
  }

  .s3-cadastro.specialSize {
    height: 100% !important;
  }

  .s3-cadastro:not(.contratos):not(.specialSize) {
    height: 100% !important;
  }
}

@media (max-height: 600px) {
  .s3-cadastro.specialSize {
    height: 100% !important;
  }
}

/* @media only screen and (min-height: 640px){
  .s3-cadastro:not(.contratos):not(.specialSize){
    height: -webkit-fill-available;
  }
} */

/* @media only screen and (max-height: 639px){
  .s3-cadastro:not(.contratos):not(.specialSize){
    height: 100% !important;
  }
} */


@media only screen and (max-width: 800px) {
  .s3-cadastro #mainWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 790) {
  .s3-cadastro #mainWrapper {
    width: 80vw;
  }
}

.s3-cadastro #mainWrapper .s3-step-calculator {
  margin-bottom: 35px;
  text-align: center;
}

.s3-cadastro #mainWrapper .s3-step-calculator .s3-calculator {
  position: relative;
  display: inline-block;
  padding: 20px 25px 16px;
  font-size: 18px;
  border-radius: 3px;
  background-color: #2879db;
  color: white;
  transition: all 300ms;
  cursor: default;
}

.s3-cadastro #mainWrapper .s3-step-calculator .s3-calculator span {
  margin-right: -5px;
}

.s3-cadastro #mainWrapper .s3-step-calculator .s3-calculator span:last-child {
  margin-right: 0;
}

.s3-cadastro #mainWrapper .s3-step-calculator .s3-calculator::after {
  content: 'Total a pagar pelo serviço';
  position: absolute;
  bottom: 100%;
  margin-bottom: -8px;
  white-space: nowrap;
  font-size: 13px;
  background-color: #00bf1b;
  color: white;
  border-radius: 3px;
  padding: 4px 9px 2px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: all 300ms;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.s3-cadastro #mainWrapper .s3-step-calculator .s3-calculator:hover {
  padding: 25px 25px 11px;
}

.s3-cadastro #mainWrapper .s3-step-calculator .s3-calculator:hover::after {
  margin-bottom: -13px;
  opacity: 1;
  visibility: visible;
}

.s3-cadastro #mainWrapper.visible,
#mainWrapperLogin {
  height: auto;
  opacity: 1;
  visibility: visible;
  top: 0;
  overflow: visible;
}

.s3-cadastro #mainWrapper:after,
.s3-cadastro #mainWrapper:before,
#mainWrapperLogin:after,
#mainWrapperLogin:before {
  content: '';
  width: 12vw;
  height: 415px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

@media (min-width: 1440px) {
  /* #mainWrapperLogin:after {
    width: 156px;
    position: absolute;
    top: 50%;
    left: 0%;
    background-image: url('../data/images/bg-cadastro-right.png');
    height: 313px;
  } */

  /* #mainWrapperLogin:before {
    position: absolute;
    top: 70%;
    left: 90%;
    background-image: url('../data/images/bg-cadastro-left.png');
    height: 313px;
  } */
}


.s3-cadastro #mainWrapper:after {
  left: 100%;

}

.s3-cadastro #mainWrapper:before,
#mainWrapperLogin:before {
  right: 100%;

}

@media only screen and (max-width: 1024px) {

  .s3-cadastro #mainWrapper:after,
  .s3-cadastro #mainWrapper:before {
    width: 0px;
    height: 0px;
  }
}

.s3-cadastro #mainWrapper header {
  margin-top: 400px;
  padding: 20px 25px 19px 20px;
  background-image: linear-gradient(173deg, #2879db 20%, #02c5ed 80%);
}

.s3-cadastro #mainWrapper header .s3-flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.s3-cadastro #mainWrapper header .s3-navigation {
  position: relative;
  top: 1px;
}

.s3-cadastro #mainWrapper header .s3-navigation ul li {
  display: inline-block;
  margin-right: 20px;
}

.s3-cadastro #mainWrapper header .s3-navigation ul li:last-child {
  margin-right: 0;
}

.s3-cadastro #mainWrapper header .s3-navigation ul li a {
  color: white;
  font-size: 14px;
  transition: all 300ms ease;
}

.s3-cadastro #mainWrapper header .s3-navigation ul li a:hover {
  color: #122143;
}

.s3-cadastro #mainWrapper header .s3-navigation ul li.active a {
  color: #122143;
}

.s3-cadastro .s3-body {
  transition: height 1000ms;
  position: relative;
}

.s3-cadastro .s3-body::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 45px;
  left: 0;
  box-shadow: 0 0 140px rgba(0, 0, 0, 0.15);
}

.s3-cadastro .s3-body .s3-step {
  position: relative;
}

.s3-cadastro .s3-body .s3-step>.s3-wrapper {
  background-color: #F6F7FA;
  padding: 20px 40px 50px;
}

.s3-cadastro .s3-body .s3-step[data-step="A"]>.s3-wrapper {
  padding: 20px 20px 30px;
}

.s3-cadastro .s3-body .s3-step[data-step="A"]>.s3-wrapper .s3-step-header {
  margin-bottom: 23px;
}

.s3-cadastro .s3-body .s3-step[data-step="B"]>.s3-wrapper {
  padding: 20px 40px 30px;
}

.s3-cadastro .s3-body .s3-step[data-step="B"]>.s3-wrapper .s3-step-header {
  margin-bottom: 23px;
}

.s3-step-header {
  margin-bottom: 25px;
  text-align: center;
  font-family: 'Open Sans';
}

.s3-step-header h3 {
  font-size: 28px;
  font-weight: 300;
  line-height: 140%;
}

.s3-step-header h4 {
  font-size: 22px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 140%;
}

.s3-step-header:last-child {
  margin-bottom: 0;
  margin: 20px 0 35px;
}

.s3-step-header:last-child h3 {
  font-weight: normal;
  color: #1388b9;
  font-weight: bold;
  margin-bottom: 15px;
}

.s3-step-header:last-child h4 {
  color: #1388b9;
}

.s3-plans {
  padding-bottom: 30px;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper {
  display: flex;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] {
  width: 100%;
  margin-right: 20px;
  background-color: white;
  position: relative;
  padding-bottom: 25px;
  text-align: center;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"]:last-child {
  margin-right: 0;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-header {
  padding: 12px 0 10px;
  color: white;
  font-size: 15px;
  text-align: center;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-price {
  font-size: 22px;
  color: #1388b9;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-price .s3-wpr {
  padding-top: 4px;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-price span:first-child {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: normal;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-content {
  padding: 30px 15px;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-content ul li {
  margin-bottom: 20px;
  line-height: 140%;
  font-size: 13px;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-content ul li:last-child {
  margin-bottom: 0;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-content p {
  margin-bottom: 20px;
  line-height: 140%;
  flex: 13px;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-content p:first-child {
  margin-top: 45px;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-content p:last-child {
  margin-bottom: 0;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-choose {
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  text-align: center;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"] .s3-choose a {
  display: inline-block;
  padding: 16px 35px 13px;
  margin-top: -1px;
  border-radius: 30px;
  color: white;
  transform: translateY(-50%);
  box-shadow: 0 2px 10px rgba(19, 136, 185, 0.3);
  transition: all 300ms;
  z-index: 20;
  background-color: white;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-1 .s3-header {
  background-color: #02C3EA;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-1 .s3-choose a {
  color: #02C3EA;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-1 .s3-choose a:hover {
  color: white;
  background-color: #02C3EA;
  box-shadow: 0 2px 3px rgba(2, 195, 234, 0.3);
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-2 .s3-header {
  background-color: #13A0E2;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-2 .s3-choose a {
  color: #13A0E2;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-2 .s3-choose a:hover {
  color: white;
  background-color: #13A0E2;
  box-shadow: 0 2px 3px rgba(19, 160, 226, 0.3);
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-3 .s3-header {
  background-color: #267BD9;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-3 .s3-choose a {
  color: #267BD9;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-3 .s3-choose a:hover {
  color: white;
  background-color: #267BD9;
  box-shadow: 0 2px 3px rgba(38, 123, 217, 0.3);
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-4 .s3-header {
  background-color: #0E56AD;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-4 .s3-choose a {
  color: #0E56AD;
}

.s3-cadastro .s3-body .s3-step .s3-plans .s3-flex-wrapper [class*="s3-column-"].s3-column-4 .s3-choose a:hover {
  color: white;
  background-color: #0E56AD;
  box-shadow: 0 2px 3px rgba(14, 86, 173, 0.3);
}

.s3-cadastro .s3-body .s3-step .s3-item {
  background-color: white;
  text-align: center;
  position: relative;
  color: #1388b9;
  box-shadow: 0 0 20px transparent;
  transition: all 300ms;
  cursor: pointer;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-wrapper {
  overflow: hidden;
  padding-bottom: 15px;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-top {
  padding: 22px 20px 20px;
  height: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-top .s3-title {
  font-size: 20px;
  font-weight: 300;
  line-height: 120%;
  z-index: 20;
  position: relative;
  transition: all 300ms;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-top:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -200px;
  margin-left: 350px;
  width: 330px;
  height: 220px;
  background-image: linear-gradient(138deg, #28deff 9%, #1fb8ff 52%, #1695ff 91%);
  transform: rotate(10deg);
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-top:after {
  content: '';
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: #ddd;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: all 100ms;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  position: relative;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-bottom:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 200px;
  margin-left: -800px;
  width: 330px;
  height: 220px;
  background-image: linear-gradient(138deg, #28deff 9%, #1fb8ff 52%, #1695ff 91%);
  transform: rotate(10deg);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-bottom .s3-starting {
  font-size: 15px;
  font-weight: 300;
  z-index: 12;
  position: relative;
  transition: all 300ms;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-bottom .s3-price {
  font-size: 32px;
  font-weight: 600;
  margin: 15px 0;
  z-index: 12;
  position: relative;
  transition: all 300ms;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-bottom .s3-info {
  font-size: 13px;
  font-weight: 300;
  z-index: 12;
  position: relative;
  transition: all 300ms;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-choose {
  margin-top: 0;
  height: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-choose a {
  display: inline-block;
  padding: 16px 35px 13px;
  background-color: white;
  margin-top: -1px;
  border-radius: 30px;
  color: #1388b9;
  transform: translateY(-50%);
  box-shadow: 0 2px 10px rgba(19, 136, 185, 0.3);
  transition: all 300ms;
  opacity: 0;
  visibility: hidden;
  z-index: 20;
}

.s3-cadastro .s3-body .s3-step .s3-item .s3-choose a:hover {
  box-shadow: 0 2px 3px rgba(19, 136, 185, 0.3);
}

.s3-cadastro .s3-body .s3-step .s3-item:hover {
  background-color: #F6F7FA !important;
  box-shadow: 0 0 30px rgba(19, 136, 185, 0.1);
}

.s3-cadastro .s3-body .s3-step .s3-item:hover .s3-top .s3-title {
  color: white;
}

.s3-cadastro .s3-body .s3-step .s3-item:hover .s3-top:before {
  opacity: 1;
  visibility: visible;
  top: -103px;
  margin-left: -161px;
}

.s3-cadastro .s3-body .s3-step .s3-item:hover .s3-top:after {
  opacity: 0;
  visibility: hidden;
}

.s3-cadastro .s3-body .s3-step .s3-item:hover .s3-bottom,
.s3-cadastro .s3-body .s3-step .s3-item:hover .s3-white {
  color: white;
}

.s3-cadastro .s3-body .s3-step .s3-item:hover .s3-bottom:before {
  visibility: visible;
  opacity: 1;
  top: -3px;
  margin-left: -161px;
}

@media (min-width: 440px) {
  .s3-step[data-step="Contratos"] .s3-wrapper .col-md-4 {
    max-width: 49% !important;
  }
}

@media (max-width: 440px) {
  .s3-step[data-step="Contratos"] .s3-wrapper .col-md-4 {
    max-width: 69% !important;
  }
}

@media (max-width: 464px) {
  .s3-step[data-step="Contratos"] .s3-wrapper .col-md-4 {
    max-width: 69% !important;
  }
}

@media (max-height: 464px) and (min-height: 369px) {
  .s3-step[data-step="Contratos"] .s3-wrapper {
    height: 125% !important;
  }
}

@media (max-width: 340px) {
  .s3-step[data-step="Contratos"] .s3-wrapper .col-md-4 {
    max-width: 79% !important;
  }
}

.s3-cadastro .s3-body .s3-step .s3-item:hover .s3-choose a {
  visibility: visible;
  opacity: 1;
}

.s3-cadastro .s3-body .s3-step .s3-step-navigation {
  padding: 0 40px;
}

.s3-cadastro .s3-body .s3-step .s3-step-navigation a {
  transform: translateY(-50%);
  margin-right: 15px;
}

.s3-cadastro .s3-body .s3-step .s3-step-navigation a:last-child {
  margin-right: 0;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .carousel-item {
  padding: 20px 20px 0;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation {
  padding-top: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation a {
  display: inline-block;
  background-color: #9399a6;
  color: white;
  font-size: 14px;
  border-radius: 3px;
  padding: 13px 15px 10px;
  border: none;
  transition: all 300ms;
  opacity: 1;
  visibility: visible;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation a:hover {
  background-color: #3e87df;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation a.s3-slick-remove {
  background-color: #f16b6b;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation a.s3-slick-remove:hover {
  background-color: #ef5454;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation a.s3-slick-add {
  background-color: #2879db;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation a.s3-slick-add:hover {
  background-color: #3e87df;
}

.s3-cadastro .s3-body .s3-step .s3-dependentes-slide .slick-navigation a.invisible {
  visibility: hidden;
  opacity: 0;
}

/* Page :: Login */
.s3-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
}

.s3-login #mainWrapper {
  position: relative;
  z-index: 10;
  top: 30px;
  box-shadow: 0 0 140px rgba(0, 0, 0, 0.15);
}

.s3-login #mainWrapper:after,
.s3-login #mainWrapper:before {
  content: '';
  width: 12vw;
  height: 415px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
}

.s3-login #mainWrapper:after {
  left: 100%;
  background-image: url('../data/images/bg-cadastro-right.png');
}

.s3-login #mainWrapper:before {
  right: 100%;
  background-image: url('../data/images/bg-cadastro-left.png');
}

.s3-login #mainWrapperLogin header {
  padding: 20px 25px 19px 20px;
  background-image: linear-gradient(173deg, #2879db 20%, #02c5ed 80%);
}

.s3-login #mainWrapper header .s3-flex-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.s3-login #mainWrapper header .s3-navigation {
  position: relative;
  top: 1px;
}

.s3-login #mainWrapper header .s3-navigation ul li {
  display: inline-block;
  margin-right: 20px;
}

.s3-login #mainWrapper header .s3-navigation ul li:last-child {
  margin-right: 0;
}

.s3-login #mainWrapper header .s3-navigation ul li a {
  color: white;
  font-size: 14px;
  transition: all 300ms ease;
}

.s3-login #mainWrapper header .s3-navigation ul li a:hover {
  color: #122143;
}

.s3-login #mainWrapper header .s3-navigation ul li.active a {
  color: #122143;
}

.s3-login .s3-body {
  background-color: #F5F6F9;
  padding: 60px;
  text-align: center;
}

.s3-login .s3-body .s3-image {
  text-align: center;
}

.s3-login .s3-body .s3-image img {
  max-width: 100%;
  height: auto;
}

.s3-login .s3-body .s3-go {
  position: absolute;
  bottom: -60px;
  left: 0;
  right: 0;
  text-align: center;
}

.s3-login .s3-body .s3-go a {
  transform: translateY(50%);
  padding-left: 70px;
  padding-right: 70px;
}

.s3-login .s3-body .s3-login-form .s3-form-item {
  display: inline-block;
  margin-bottom: 40px;
  position: relative;
  width: 340px;
}

.s3-checkbox {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  height: 39px;
  padding: 12px 0 8px;
}

.s3-checkbox:last-child {
  margin-right: 0;
}

.s3-checkbox label {
  display: inline-block;
  position: relative;
  padding: 2px 0 0 28px;
  vertical-align: top;
  color: #a7abb6;
  cursor: pointer;
  transition: color 0.3s;
}

.s3-checkbox label::before {
  content: '';
  border: 1px solid #9298A6;
  transition: all 0.3s;
}

.s3-checkbox input,
.s3-checkbox label::before {
  width: 20px;
  height: 20px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.s3-checkbox input {
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  z-index: 100;
}

.s3-checkbox input:checked+label {
  color: #2879db;
}

.s3-checkbox input:checked+label::before {
  border-color: #9399a6;
}

.s3-checkbox input:checked+label+svg {
  opacity: 1;
  visibility: visible;
}

.s3-checkbox svg {
  position: absolute;
  width: 13px;
  height: 14px;
  top: 50%;
  margin-top: -6px;
  left: 3px;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms;
}

.s3-checkbox svg path {
  stroke: #2879db;
  stroke-width: 14px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.s3-login .s3-body .s3-login-form .s3-form-item:not(.s3-cb)::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 100%;
  height: 3px;
  background-color: #2879db;
  transition: all 500ms;
}

.s3-login .s3-body .s3-login-form .s3-form-item label.down {
  text-align: left;
  position: absolute;
  top: 12px;
  left: 0;
  padding-left: 12px;
  right: 0;
  font-size: 14px;
  padding-bottom: 11px;
  transition: all 500ms;
  overflow: hidden;
  cursor: text;
}

.s3-login .s3-body .s3-login-form .s3-form-item label.up {
  content: attr(data-content);
  position: absolute;
  top: -15px;
  left: 0px;
  font-size: 13px;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms;
  color: #9399a6;
}

.s3-login .s3-body .s3-login-form .s3-form-item input:not([type="checkbox"]) {
  width: 100%;
  background-color: transparent;
  padding: 7px 12px 7px;
  border: none;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  color: #2879db;
}

.s3-login .s3-body .s3-login-form .s3-form-item.focus::after {
  right: 0;
}

.s3-login .s3-body .s3-login-form .s3-form-item.focus label.down {
  visibility: hidden;
  opacity: 0;
}

.s3-login .s3-body .s3-login-form .s3-form-item.focus label.up {
  opacity: 1;
  visibility: visible;
  left: 12px;
}

.s3-login .s3-body .s3-login-form hr {
  border-color: #ddd;
  margin: 15px 0 55px;
}

.s3-login .s3-body .s3-login-form .s3-forgot-password {
  margin-bottom: 10px;
}

.s3-login .s3-body .s3-login-form .s3-forgot-password a {
  color: #9399a6;
  transition: all 300ms ease;
}

.s3-login .s3-body .s3-login-form .s3-forgot-password a:hover {
  color: #2879db;
}

/* Standard Pages */
.page-quem_somos .s3-page-header,
.page-nossos_servicos .s3-page-header,
.page-faq .s3-page-header {
  padding: 90px 0 88px;
  background-image: url('../data/images/bg-page-header.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
}

.page-quem_somos .s3-page-header h1,
.page-nossos_servicos .s3-page-header h1,
.page-faq .s3-page-header h1 {
  font-size: 45px;
  font-weight: normal;
  margin-bottom: 25px;
  color: white;
}

.page-faq .s3-page-header h1 strong {
  font-size: 45px;
  font-weight: 900;
  margin-bottom: 25px;
  color: white;
}

@media (max-width: 450px) {

  .page-quem_somos .s3-page-header h1,
  .page-nossos_servicos .s3-page-header h1,
  .page-faq .s3-page-header h1 {
    font-size: 38px;
  }
}

.page-quem_somos .s3-page-header p,
.page-nossos_servicos .s3-page-header p,
.page-faq .s3-page-header p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: white;
}

@media (max-width: 450px) {

  .page-quem_somos .s3-page-header p br,
  .page-nossos_servicos .s3-page-header p br,
  .page-faq .s3-page-header p br {
    display: none;
  }
}

.page-quem_somos .s3-section-cta,
.page-nossos_servicos .s3-section-cta,
.page-faq .s3-section-cta {
  background-image: linear-gradient(180deg, #2fd2fd 0%, #21a0fc 100%);
  padding: 90px 0;
}

.page-quem_somos .s3-section-cta h2,
.page-nossos_servicos .s3-section-cta h2,
.page-faq .s3-section-cta h2 {
  text-align: center;
  font-size: 35px;
  color: white;
  font-weight: normal;
}

.page-quem_somos .s3-section-cta h2 span,
.page-nossos_servicos .s3-section-cta h2 span,
.page-faq .s3-section-cta h2 span {
  position: relative;
  top: -13px;
  margin-right: 20px;
}

@media (max-width: 1024px) {

  .page-quem_somos .s3-section-cta h2 span,
  .page-nossos_servicos .s3-section-cta h2 span,
  .page-faq .s3-section-cta h2 span {
    display: block;
    margin-bottom: 15px;
    top: 0;
    margin-right: 0;
  }
}

@media (max-width: 350px) {

  .page-quem_somos .s3-section-cta h2 span,
  .page-nossos_servicos .s3-section-cta h2 span,
  .page-faq .s3-section-cta h2 span {
    line-height: 140%;
  }
}

.page-quem_somos .s3-section-cta h2 a,
.page-nossos_servicos .s3-section-cta h2 a,
.page-faq .s3-section-cta h2 a {
  margin-top: 10px;
}

@media (max-width: 768px) {

  .page-quem_somos .s3-section-cta h2,
  .page-nossos_servicos .s3-section-cta h2,
  .page-faq .s3-section-cta h2 {
    font-size: 32px;
  }
}

@media (max-width: 350px) {

  .page-quem_somos .s3-section-cta h2,
  .page-nossos_servicos .s3-section-cta h2,
  .page-faq .s3-section-cta h2 {
    font-size: 28px;
  }
}

/* Page :: Quem Somos */
.page-quem_somos .s3-section-optimize {
  padding: 100px 0;
}

.page-quem_somos .s3-section-optimize h2 {
  color: #1388b9;
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 55px;
  text-align: center;
}

.page-quem_somos .s3-section-optimize .content p {
  font-size: 18px;
  color: #9399a6;
  line-height: 24px;
  font-weight: 300;
}

.page-quem_somos .s3-section-optimize .content p:last-child {
  margin-bottom: 0;
}

.page-quem_somos .s3-section-optimize .content p.image img {
  margin-top: 55px;
  max-width: 100%;
}

@media (max-width: 768px) {
  .page-quem_somos .s3-section-optimize .content p.image {
    display: none;
  }
}

.page-quem_somos .s3-section-steps {
  padding-top: 10px;
  padding-bottom: 130px;
  text-align: center;
}

.page-quem_somos .s3-section-steps h2 {
  color: #1388b9;
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 55px;
  line-height: 140%;
}

.page-quem_somos .s3-section-steps h2 strong {
  font-weight: 600;
  color: #1388b9;
  font-size: 38px;
}

@media (max-width: 450px) {
  .page-quem_somos .s3-section-steps h2 {
    font-size: 36px;
  }
}

@media (max-width: 350px) {
  .page-quem_somos .s3-section-steps h2 {
    font-size: 28px;
  }
}

.page-quem_somos .s3-section-steps .s3-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 147px;
  margin-bottom: 30px;
  width: 100%;
}

.page-quem_somos .s3-section-steps .s3-steps .item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border: 2px solid #1388b9;
  border-radius: 50%;
}

.page-quem_somos .s3-section-steps .s3-steps .item span {
  font-size: 32px;
  color: #1388b9;
  position: relative;
  top: 3px;
}

.page-quem_somos .s3-section-steps .s3-steps>span {
  width: 260px;
  height: 2px;
  background-color: #1388b9;
  position: relative;
}

.page-quem_somos .s3-section-steps .s3-steps>span::after {
  content: '';
  width: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #1388b9;
}

@media (max-width: 768px) {
  .page-quem_somos .s3-section-steps .s3-steps {
    display: none;
  }
}

.page-quem_somos .s3-section-steps {
  padding: 20px;
  /* Adjust the padding value as desired */
  background-color: white;
  box-shadow: 0 -10px 30px rgba(0, 0, 0, 0.15) !important;
  min-height: 790px;
  display: flex;
  flex-direction: column;
}

.block {
  padding: 40px;
  /* Ajuste o valor do espaçamento interno (padding) conforme desejado */
  background-color: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px;
  min-height: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Adicione esta linha */
}

.block>* {
  margin-bottom: 20px;
  /* Ajuste o valor da margem conforme desejado */
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row>* {
  margin-bottom: 12px;
  /* Ajuste o valor da margem conforme desejado */
}

.block-title {
  font-size: 22px;
  font-weight: 300;
  color: #1388b9;
  line-height: 28px;
  text-align: center;

}

.page-quem_somos .s3-section-steps .icon {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-quem_somos .s3-section-steps .block h3 {
  margin: 20px 0;
  color: #1388b9;
  font-size: 24px;
  line-height: 28px;
  font-weight: normal;
  min-height: 84px;
}

@media (max-width: 768px) {
  .page-quem_somos .s3-section-steps .block h3 {
    min-height: auto !important;
  }
}

.page-quem_somos strong {
  font-weight: 900;
  font-size: 18px;
  color: #9399a6;
  line-height: 24px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .page-quem_somos .s3-section-steps {
    margin-bottom: 30px;
    height: auto !important;
    min-height: auto !important;
  }
}

/* Nossos Serviços */
.page-nossos_servicos .s3-section-plans {
  padding: 80px 0 120px;
}

.page-nossos_servicos .s3-section-plans h2 {
  color: #1388b9;
  text-align: center;
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 78px;
}

.page-nossos_servicos .s3-section-plans .s3-plan {
  margin-bottom: 80px;
  border-top: 3px solid #1388b9;
  position: relative;
}

.page-nossos_servicos .s3-section-plans .s3-plan:last-child {
  margin-bottom: 0;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-thumbnail {
  position: relative;
  top: -3px;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-thumbnail img {
  width: 100%;
  height: auto;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-info {
  padding-top: 49px;
  text-align: center;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-info h3 {
  font-size: 20px;
  font-weight: normal;
  color: #1388b9;
  line-height: 30px;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-info h3::after {
  content: '';
  width: 150px;
  height: 1px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-info .price {
  margin-bottom: 23px;
  font-size: 40px;
  font-weight: bold;
  color: #1388b9;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-info .price span {
  font-size: 20px;
  font-weight: normal;
  position: relative;
  top: -7px;
  margin-left: 5px;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-info .s3-see-more {
  margin-top: 20px;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-info .s3-see-more a {
  color: #1388b9;
}

@media (max-width: 1024px) {
  .page-nossos_servicos .s3-section-plans .s3-plan .s3-info {
    padding-top: 0;
  }
}

@media (max-width: 450px) {
  .page-nossos_servicos .s3-section-plans .s3-plan .s3-info {
    margin-bottom: 30px;
  }
}

.page-nossos_servicos .s3-section-plans .s3-plan ul.s3-list {
  padding-top: 30px;
}

.page-nossos_servicos .s3-section-plans .s3-plan ul.s3-list li {
  font-size: 14px;
  color: #9399a6;
  margin-bottom: 10px;
  line-height: 140%;
  padding-left: 22px;
  background-image: url('../data/images/icon-check.png');
  background-repeat: no-repeat;
  background-position: left top 5px;
}

.page-nossos_servicos .s3-section-plans .s3-plan ul.s3-list li:last-child {
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  .page-nossos_servicos .s3-section-plans .s3-plan ul.s3-list {
    padding: 0;
  }
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info {
  position: absolute;
  top: -3px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  border: 3px solid #1388b9;
  z-index: 90;
  visibility: hidden;
  opacity: 0;
  transition: all 800ms;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info.active {
  opacity: 1;
  visibility: visible;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .s3-close {
  position: absolute;
  top: -15px;
  right: -15px;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .s3-close a {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #1388b9;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  transition: all 300ms;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .s3-close a:hover {
  background-color: #1177a2;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .wrapper {
  padding: 30px;
  overflow-y: scroll;
  height: 100%;
}

@media (max-width: 1024px) {
  .page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .wrapper {
    overflow-y: hidden;
  }
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .wrapper p {
  font-size: 16px;
  line-height: 22px;
}

.page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .wrapper p:last-child {
  margin-bottom: 0;
}

@media (max-width: 450px) {
  .page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .wrapper p {
    text-align: justify;
  }
}

@media (max-width: 1024px) {
  .page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info {
    position: static;
    visibility: visible;
    opacity: 1;
    border: none;
    border-top: 1px solid rgba(147, 153, 166, 0.2);
    padding-top: 30px;
    margin-top: 30px;
  }

  .page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .s3-close {
    display: none;
  }

  .page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .wrapper {
    padding: 0;
  }

  .page-nossos_servicos .s3-section-plans .s3-plan .s3-more-info .wrapper p {
    font-size: 14px;
    line-height: 160%;
  }
}

@media (max-width: 1024px) {
  .page-nossos_servicos .s3-section-plans .s3-plan {
    border: 2px solid #1388b9;
    padding: 30px;
    margin-bottom: 40px;
  }
}

/* FAQ */
.page-faq .s3-section-faq {
  padding: 80px 0;
}

.page-faq .s3-section-faq h2 {
  color: #1388b9;
  text-align: center;
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 78px;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul {
  display: flex;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li {
  width: 100%;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li a {
  display: block;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: 15px;
  color: #1388b9;
  transition: all 300ms;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li a span {
  font-size: 14px;
  position: relative;
  top: 2px;
  z-index: 10;
  line-height: 130%;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li a::before,
.page-faq .s3-section-faq .s3-faq .el-tabs ul li a::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 300ms;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li a::before {
  z-index: 5;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li a::after {
  z-index: 3;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li a:hover {
  color: white;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li a:hover::before {
  opacity: 0;
}

@media (max-width: 450px) {
  .page-faq .s3-section-faq .s3-faq .el-tabs ul li a {
    background-color: #eaeaea;
    text-align: center;
    display: block;
    height: auto;
    padding: 20px 0;
  }

  .page-faq .s3-section-faq .s3-faq .el-tabs ul li a::before,
  .page-faq .s3-section-faq .s3-faq .el-tabs ul li a::after {
    display: none;
  }
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li:nth-child(1) a::before {
  background-image: url('../data/images/img-faq_1-1.png');
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li:nth-child(1) a::after {
  background-image: url('../data/images/img-faq_1-2.png');
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li:nth-child(2) a::before {
  background-image: url('../data/images/img-faq_2-1.png');
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li:nth-child(2) a::after {
  background-image: url('../data/images/img-faq_2-2.png');
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li:nth-child(3) a::before {
  background-image: url('../data/images/img-faq_3-1.png');
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li:nth-child(3) a::after {
  background-image: url('../data/images/img-faq_3-2.png');
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li.active a span {
  color: white;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li:not(.active) a span:hover {
  color: white;
}

.page-faq .s3-section-faq .s3-faq .el-tabs ul li.active a::before {
  opacity: 0;
}

@media (max-width: 450px) {
  .page-faq .s3-section-faq .s3-faq .el-tabs ul li:not(:last-child) {
    border-right: 1px solid white;
  }

  .page-faq .s3-section-faq .s3-faq .el-tabs ul li.active a {
    background-color: #1388b9;
  }
}

@media (max-width: 350px) {
  .page-faq .s3-section-faq .s3-faq .el-tabs ul {
    display: block;
  }

  .page-faq .s3-section-faq .s3-faq .el-tabs ul li {
    border: none !important;
  }
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab {
  display: none;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab.active {
  display: block;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab h3 {
  margin: 40px 0 35px;
  text-align: center;
  font-weight: normal;
  color: #1388b9;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item {
  margin-bottom: 30px;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item:last-child {
  margin-bottom: 0;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item header a {
  color: #003170;
  font-size: 16px;
  line-height: 140%;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item header a::before {
  content: '+';
  margin-right: 7px;
  display: inline-block;
  width: 11px;
  transform: rotate(-90deg);
  transition: all 300ms;
  position: relative;
}

@media (max-width: 450px) {
  .page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item header a::before {
    display: none;
  }
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item.active header a::before {
  content: '-';
  transform: rotate(0);
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item .content {
  height: 0;
  overflow: hidden;
  transition: height 500ms ease;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item .content .wrapper {
  padding: 20px 18px 0;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item .content .wrapper p {
  line-height: 22px;
  font-size: 16px;
}

.page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item .content .wrapper p:last-child {
  margin-bottom: 0;
}

@media (max-width: 450px) {
  .page-faq .s3-section-faq .s3-faq .el-tabs .tab .el-accordion .item {
    text-align: center;
  }
}

.page-faq .s3-section-contact .image img {
  max-width: 100%;
}

@media (max-width: 450px) {
  .page-faq .s3-section-contact .image {
    text-align: center;
    margin-bottom: 30px;
  }
}

/* Blog */
.page-blog .s3-page-header {
  padding: 90px 0 88px;
  background-image: url('../data/images/bg-page-header.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
}

.page-blog .s3-page-header h1 {
  font-size: 45px;
  font-weight: 300;
  margin-bottom: 25px;
  line-height: 120%;
}

.page-blog .s3-page-header h1 strong {
  font-weight: medium;
}

.page-blog .s3-page-header p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.page-blog .s3-section-blog {
  padding: 100px 0 0;
}

.page-blog .s3-section-blog .post {
  margin-bottom: 100px;
}

.page-blog .s3-section-blog .post-thumbnail a {
  display: block;
  width: 100%;
  height: 280px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.page-blog .s3-section-blog .post-header {
  padding: 30px 0 19px;
}

.page-blog .s3-section-blog .post-header .meta {
  color: #ccc;
  font-size: 14px;
  margin-bottom: 10px;
}

.page-blog .s3-section-blog .post-header .meta .date {
  display: inline-block;
}

.page-blog .s3-section-blog .post-header .meta .date::after {
  content: '–';
  margin: 0 10px;
}

.page-blog .s3-section-blog .post-header .meta .author {
  display: inline-block;
}

.page-blog .s3-section-blog .post-header .meta .author a {
  color: #ccc;
  transition: all 300ms;
}

.page-blog .s3-section-blog .post-header .meta .author a:hover {
  color: #1388b9;
}

.page-blog .s3-section-blog .post-header h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.page-blog .s3-section-blog .post-header h3 a {
  color: #003170;
}

.page-blog .s3-section-blog .post-excerpt {
  margin-bottom: 20px;
  min-height: 132px;
}

.page-blog .s3-section-blog .post-excerpt p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #003170;
}

.page-blog .s3-section-blog .post-link a {
  color: #9399a6;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.page-blog .s3-section-blog .s3-list {
  display: none;
}

.page-blog .s3-section-blog #loader {
  margin-bottom: 100px;
}

.page-blog .s3-section-blog-internal {
  padding: 100px 0;
}

.page-blog .s3-section-blog-internal .post-thumbnail {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;
  margin-bottom: 30px;
}

.page-blog .s3-section-blog-internal .post-header .meta {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 19px;
}

.page-blog .s3-section-blog-internal .post-header .meta .date {
  display: inline-block;
}

.page-blog .s3-section-blog-internal .post-header .meta .date::after {
  content: '–';
  margin: 0 7px 0 10px;
}

.page-blog .s3-section-blog-internal .post-header .meta .author {
  display: inline-block;
}

.page-blog .s3-section-blog-internal .post-header .meta .author a {
  color: #ccc;
  transition: all 300ms;
}

.page-blog .s3-section-blog-internal .post-header .meta .author a:hover {
  color: #1388b9;
}

.page-blog .s3-section-blog-internal .post-header h3 {
  font-size: 30px;
  font-weight: bold;
  color: #1388b9;
  margin-bottom: 45px;
}

.page-blog .s3-section-blog-internal .post-content {
  padding-bottom: 50px;
  margin-bottom: 20px;
  border-bottom: 3px solid #d7d7d7;
}

.page-blog .s3-section-blog-internal .post-content>*:last-child {
  margin-bottom: 0;
}

.page-blog .s3-section-blog-internal .post-content p {
  line-height: 22px;
  font-size: 16px;
  color: #003170;
  font-weight: 300;
  margin-bottom: 20px;
}

.page-blog .s3-section-blog-internal .post-content blockquote {
  font-size: 24px;
  color: #003170;
  line-height: 33px;
  border: none;
  padding: 0 100px 0 140px;
  margin: 50px 0 45px;
  background-image: url('../data/images/icon-quotes.png');
  background-repeat: no-repeat;
  background-position: top left 65px;
}

.page-blog .s3-section-cta {
  background-image: linear-gradient(180deg, #2fd2fd 0%, #21a0fc 100%);
  padding: 90px 0;
}

.page-blog .s3-section-cta h2 {
  text-align: center;
  font-size: 35px;
  color: white;
  font-weight: normal;
}

.page-blog .s3-section-cta h2 span {
  position: relative;
  top: -13px;
  margin-right: 20px;
}

.page-blog .s3-section-cta h2 a {
  margin-top: 10px;
}

/* Contato */
.page-contato .s3-page-header {
  padding: 90px 0 88px;
  background-image: url('../data/images/bg-page-header.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
}

.page-contato .s3-page-header h1 {
  font-size: 45px;
  font-weight: normal;
  margin-bottom: 25px;
}

.page-contato .s3-page-header p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.page-contato .s3-section-contact {
  padding: 100px 0 0;
}

.page-contato .s3-section-contact .s3-image {
  text-align: center;
  margin-bottom: 60px;
}

.page-contato .s3-section-contact .s3-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 450px) {
  .page-contato .s3-section-contact .s3-image {
    display: none;
  }
}

.page-contato .s3-section-contact h2 {
  font-size: 32px;
  font-weight: 300;
  color: #1388b9;
  margin-bottom: 20px;
  text-align: center;
}

.page-contato .s3-section-contact h3 {
  font-size: 24px;
  font-weight: 300;
  color: #1388b9;
  margin-bottom: 40px;
  text-align: center;
}

.page-contato .s3-section-contact .s3-text {
  margin-bottom: 40px;
}

.page-contato .s3-section-contact .s3-text p {
  line-height: 22px;
  font-size: 16px;
  text-align: center;
}

.page-contato .s3-section-cta {
  background-image: linear-gradient(180deg, #2fd2fd 0%, #21a0fc 100%);
  padding: 90px 0;
}

.page-contato .s3-section-cta h2 {
  text-align: center;
  font-size: 35px;
  color: white;
  font-weight: normal;
}

.page-contato .s3-section-cta h2 span {
  position: relative;
  top: -13px;
  margin-right: 20px;
}

.page-contato .s3-section-cta h2 a {
  margin-top: 10px;
}

/* Standard Page */
.page-standard .s3-page-header {
  padding: 90px 0 88px;
  background-image: url('../data/images/bg-page-header.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
}

.page-standard .s3-page-header h1 {
  font-size: 45px;
  font-weight: normal;
}

.page-standard .s3-section-page-content {
  padding: 100px 0;
}

.page-standard .s3-section-page-content h3 {
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  line-height: 140%;
}

.page-standard .s3-section-page-content p {
  line-height: 22px;
  font-size: 16px;
  text-align: justify;
  margin-bottom: 20px;
}

.page-standard .s3-section-page-content p:last-child {
  margin-bottom: 0;
}

.page-standard .s3-section-cta {
  background-image: linear-gradient(180deg, #2fd2fd 0%, #21a0fc 100%);
  padding: 90px 0;
}

.page-standard .s3-section-cta h2 {
  text-align: center;
  font-size: 35px;
  color: white;
  font-weight: normal;
}

.page-standard .s3-section-cta h2 span {
  position: relative;
  top: -13px;
  margin-right: 20px;
}

.page-standard .s3-section-cta h2 a {
  margin-top: 10px;
}

/* Footer */
#mainFooter {
  padding: 80px 0 30px;
  background-color: #122143;
  background-image: url('../data/images/bg-footer.png');
  background-repeat: no-repeat;
  background-position: left center;
}

#mainFooter h3 {
  font-size: 18px;
  color: white;
  font-weight: 400;
  margin-bottom: 20px;
}

#mainFooter p {
  font-size: 13px;
  line-height: 160%;
  color: white;
}

#mainFooter ul.menu li {
  line-height: 160%;
}

#mainFooter ul.menu li a {
  color: white;
  font-size: 13px;
  transition: all 300ms ease;
}

#mainFooter ul.menu li a:hover {
  color: #d7f0f8;
}

#mainFooter ul.social {
  margin-top: 23px;
}

#mainFooter ul.social li {
  display: inline-block;
  margin-right: 10px;
}

#mainFooter ul.social li:last-child {
  margin-right: 0;
}

#mainFooter ul.social li a {
  color: white;
  font-size: 16px;
}

@media (max-width: 414px) {
  #mainFooter ul.social {
    text-align: center;
  }
}

#mainFooter .s3-copyright {
  margin-top: 75px;
}

#mainFooter .s3-copyright p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  #mainFooter {
    background-image: none;
  }

  #mainFooter .s3-top {
    margin-bottom: 60px;
  }

  #mainFooter .s3-copyright {
    margin-top: 55px;
  }
}

@media (max-width: 740px) {
  #mainFooter {
    text-align: center;
  }
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.textoBranco {
  color: #fff;
}

.align-center {
  text-align: center;
}

.textoAzulEscuro {
  color: #1589ba;
}

.textoAzulClaro {
  color: #30a5d6;
}


@media (max-width: 991px) and (min-width: 881px) {
  .s3-cadastro.container [data-step="Contratos"] {
    width: 70vw;
  }
}

@media (max-width: 880px) and (min-width: 769px) {
  .s3-cadastro.container [data-step="Contratos"] {
    width: 80vw;
  }
}

@media (max-height : 470x) {
  .s3-cadastro .s3-body .s3-step>.s3-wrapper {
    padding: 0 !important;
  }
}


@media (max-height : 450x) {
  .s3-cadastro.container [data-step="Contratos"]>.s3-wrapper {
    height: 125vh;
  }
}

@media (max-height : 370px) {
  .s3-cadastro.container [data-step="Contratos"]>.s3-wrapper {
    height: 150vh;
  }
}

@media (max-height : 310px) {
  .s3-cadastro.container [data-step="Contratos"]>.s3-wrapper {
    height: 175vh;
  }
}

@media (min-height : 220px) and (max-heigth: 270px) {
  .s3-cadastro.container [data-step="Contratos"]>.s3-wrapper {
    height: 200vh;
  }
}

@media (min-height : 220px) and (max-heigth: 270px) {
  .s3-cadastro.container [data-step="Contratos"]>.s3-wrapper {
    height: 200vh;
  }
}


@media (max-width: 768px) and (max-height: 480px) {
  .s3-cadastro[data-step="C"] {
    height: 100% !important;
  }
}

@media(max-width: 768px) {

  .s3-cadastro[data-step="F2"],
  .s3-cadastro[data-step="F3"] {
    height: 100% !important;
  }
}

@media(max-width: 768px) and (max-height: 700px) {
  .s3-cadastro[data-step="F1"] {
    height: 100% !important;
  }
}

@media(max-width: 500px) and (max-height: 530px) {
  .s3-cadastro[data-step="Z"] {
    height: 100% !important;
  }
}

.col-md-10 {
  max-width: 100% !important;
}

.showContent {
  transition: all 400ms;
  height: 100% !important;
}

#landingSubtitle {
  font-weight: 800;
  color: #fff;
  margin-top: -30px !important;
}

#landingInvSubtitle {
  font-weight: 800;
  color: #fff;
  text-indent: -5px;
}

.cntrl-invest {
  padding: 100px 0;
  background-color: #02c5ed;
}

.cntrl-invest h2 {
  color: #1388b9;
  text-align: center;
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 78px;
}

.cntrl-invest .cards_invest {
  width: 100%;
  display: flex;
  justify-content: center;
}

/*centraliza imagem card*/
.cntrl-invest .cards_invest .card {
  margin: 0 30px;
  padding: 30px;
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auto-calculo-ir {
  padding: 100px 0;
  color: white;
}

.auto-calculo-ir h2 {
  color: #1388b9;
  text-align: center;
  font-size: 38px;
  font-weight: 300;
  margin-bottom: 78px;
}

/*cards azuis*/
.auto-calculo-ir .card {
  margin: 8px auto;
  width: 100%;
  height: auto;
  padding: 30px;
  background-color: #1388b9;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auto-calculo-ir .card h5 {
  color: white;
  font-size: 18px;
}


.auto-calculo-ir .card .card-body p {
  color: rgba(255, 255, 255, 0.71);
  padding: 20px 0;
  font-size: 14px;
}
