header#mainHeader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 99999;
}

.logoColor{
    max-width: 85%;
}

@media (max-width: 768px){
    .logoColor{
        display: none;
    }
    .smallLogoColor{
        display: inline;
    }
}

@media (min-width: 768px){
    .logoColor{
        display: inline;
    }
    .smallLogoColor{
        display: none;
    }
}